import React from "react";
import { makeStyles } from "@material-ui/styles";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import PageTitle from "../components/PageTitle";
import Container from "@material-ui/core/Container";
import { useStaticQuery, graphql } from "gatsby";
import Fade from "react-reveal/Fade";
import Paper from "@material-ui/core/Paper";
import PDFIcon from "@material-ui/icons/CloudDownloadRounded";
import Fab from "@material-ui/core/Fab";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    borderRadius: theme.borderRadius,
    boxShadow: theme.shadows[8]
  },
  resume: {
    maxWidth: 1000,
    marginTop: theme.spacing(-8),

    "& h2": {
      color: theme.palette.primary.main,
      fontSize: "18pt"
    },
    "& h3": {
      marginBottom: 0,
      marginTop: 20
    },
    "& *": {
      fontFamily: "'PT Serif', sans-serif"
    },
    "& h4": {
      color: "#34495e",
      fontWeight: "normal",
      fontSize: "10pt",
      marginTop: 0,
      marginBottom: 3
    },
    "& p": {
      marginTop: 3,
      marginBottom: 2
    }
  },
  downloadWrapper: {
    maxWidth: 1000,
    marginTop: theme.spacing(4)
  }
}));

const Resume = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query ResumeQuery {
      contentfulResume {
        content {
          json
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Résumé" />
      <PageTitle bottomPadding>Résumé</PageTitle>
      <Container className={classes.resume}>
        <Fade up duration={300}>
          <Paper className={classes.paper}>
            {documentToReactComponents(data.contentfulResume.content.json)}
          </Paper>
        </Fade>
      </Container>
      <Container className={classes.downloadWrapper}>
        <div className={classes.download}>
          <Fab
            variant="extended"
            color="primary"
            component="a"
            href="/Resume.pdf"
            target="_blank"
          >
            <PDFIcon style={{ marginRight: 10 }} />
            View as PDF
          </Fab>
        </div>
      </Container>
    </Layout>
  );
};

export default Resume;
